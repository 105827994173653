import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';
import { ExternalTPComponent } from './components/pages/external-tp/external-tp.component';
import { PrivacyPageComponent } from './components/pages/privacy-page/privacy-page.component';
import { SupportPageComponent } from './components/pages/support-page/support-page.component';

const routes: Routes = [
    {path: '', component: AppLandingComponent},
    {path: 'privacy-policy', component: PrivacyPageComponent},
    {path: 'external-tp', component: ExternalTPComponent},
    {path: 'support', component: SupportPageComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}