<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="logo navbar-brand" href="/#home">
            <h3>Teleprompter 365</h3>
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav" *ngIf="(location == '/')">
                <li class="nav-item" *ngFor="let navItem of appLandingNavItem;"><a href="#{{navItem.id}}" class="nav-link">{{navItem.title}}</a></li>
            </ul>

            <ul class="navbar-nav" *ngIf="(location == '/external-tp')">
                <li class="nav-item" *ngFor="let navItem of bookLandingNavItem;"><a href="#{{navItem.id}}" class="nav-link">{{navItem.title}}</a></li>
            </ul>

            <ul class="navbar-nav" *ngIf="(location == '/saas-landing')">
                <li class="nav-item" *ngFor="let navItem of saasLandingNavItem;"><a href="#{{navItem.id}}" class="nav-link">{{navItem.title}}</a></li>
            </ul>

            <ul class="navbar-nav" *ngIf="(location == '/products-landing')">
                <li class="nav-item" *ngFor="let navItem of productsLandingNavItem;"><a href="#{{navItem.id}}" class="nav-link">{{navItem.title}}</a></li>
            </ul>
        </div>
    </div>
</nav>