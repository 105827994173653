import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-faq',
    templateUrl: './app-faq.component.html',
    styleUrls: ['./app-faq.component.scss']
})
export class AppFaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Frequently Asked Questions',
            paragraphText: 'Check out the most asked questions about using a teleprompter'
        }
    ]
    accordionItem: Content[] = [
        {
            title: 'Why should I use a teleprompter?',
            paragraph: "With a teleprompter you can record multiple videos that are more consistent. You can better pace yourself and use the right wording to deliver the best possible message to your audience."
        },
        {
            title: 'Can I import content to the Teleprompter 365 app?',
            paragraph: "You can paste any text to the Teleprompter app from any other app. Keep in mind that the formating may not be maintain. This is fine as you only want to see high contrast text when recording the video."
        },
        {
            title: 'What is the right pace for videos?',
            paragraph: "The app defaults the value to 150 words per minute. This is probably the best value for most videos but you do have the option to adjust this value. We suggest you try different values and see what works best for you."
        },
        {
            title: 'What is the purpose of the visual guidelines?',
            paragraph: "This app gives you the option to show a visual guideline while recording the video. The idea is to read the text close to the guideline. This will help you keep your eyes closer to the camera and look engaged to your audience."
        },
        {
            title: 'Can I add pauses to the video?',
            paragraph: "If you want to add pauses to the video, we recommend you add multiple empty lines. Lines will account for pauses as the app calculates the scrolling speed for the script"
        },
        {
            title: 'How do I share my videos?',
            paragraph: "Open the video you recorded. In the playback controls you will see the option to share the video. You can share the video with any app that accepts videos in your device. Including saving the video into your camera roll."
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Content {
    title : string;
    paragraph : string;
}