import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-about',
    templateUrl: './app-about.component.html',
    styleUrls: ['./app-about.component.scss']
})
export class AppAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/app-about.png'
        }
    ]
    aboutContent = [
        {
            title: 'Produce high quality videos',
            paragraphList: [
                {
                    paragraph: 'A teleprompter can help you produce higher quality videos. Ensure you keep the right pace and keep the video to a specific length.',
                },
                {
                    paragraph: 'You can record as many videos as you want for the same script and pick the best one. All videos\' length are the same.',
                }
            ],
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Records as many videos as you want"
                },
                {
                    itemIcon: "las la-check",
                    item: "Pace yourself by setting the words per minute speed (150 is the default)"
                },
                {
                    itemIcon: "las la-check",
                    item: "Use visual guidelines to keep your eyes close to the camera"
                },
                {
                    itemIcon: "las la-check",
                    item: "Share your videos in your favorite social media platform"
                },
                {
                    itemIcon: "las la-check",
                    item: "No watermarks"
                },
                {
                    itemIcon: "las la-check",
                    item: "Automatic scroll or scroll text manually"
                },
                {
                    itemIcon: "las la-check",
                    item: "Automatic saving of videos into your camera roll"
                },
                {
                    itemIcon: "las la-check",
                    item: "Mark as favorite any your scripts and videos to filter other ones"
                },
                {
                    itemIcon: "las la-check",
                    item: "Record in both portrait and landscape mode"
                }
            ]
        }
    ];

}
class Image {
    img : string;
}