import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-download',
    templateUrl: './app-download.component.html',
    styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    
    appDownloadImage: Image[] = [
        {
            img: 'assets/img/camera-config.png'
        }
    ]
    appDownloadContent: Content[] = [
        {
            title: 'Download TelePrompter 365',
            paragraph1: 'Use a free fully-featured teleprompter app with no limitations or constraints.',
            paragraph2: 'Start producing high quality videos and deliver your messages more effectively',
            appStoreImage: 'assets/img/store/appstore.png',
            appStoreURL: 'https://apps.apple.com/us/app/teleprompter-365/id1527009157',
            playStoreImage: 'assets/img/store/playstore.png',
            playStoreURL: 'https://play.google.com/store/apps/details?id=com.jcxsoftware.teleprompter365'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    title : string;
    paragraph1 : string;
    paragraph2 : string;
    appStoreImage : string;
    appStoreURL : string;
    playStoreImage: string;
    playStoreURL: string;
}