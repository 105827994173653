<div id="home" class="app-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>{{Content.title}}</h1>
                    <p>{{Content.paragraph}}</p>
                    <div class="banner-holder">
                        <a [href]="Content.appStoreURL"><img [src]="Content.appStoreImage" alt="AppStore"></a>
                        <a [href]="Content.playStoreURL"><img [src]="Content.playStoreImage" alt="PlayStore"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <!--
                <div class="banner-image" *ngFor="let Image of bannerImage;">
                    <img [src]="Image.img" alt="image">
                </div>
                -->
                <div class="banner-image">
                    <video id="video-banner" [src]="videoDemo" type="video/mp4" frameborder="0" autoplay loop muted >
                    </video> 
                </div>
            </div>
        </div>
    </div>
</div>