import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent: Content[] = [
        {
            title: 'Teleprompter 365',
            paragraph: 'Free full-featured teleprompter app for your phone and tablet',
            appStoreImage: 'assets/img/store/appstore.png',
            appStoreURL: 'https://apps.apple.com/us/app/teleprompter-365/id1527009157',
            playStoreImage: 'assets/img/store/playstore.png',
            playStoreURL: 'https://play.google.com/store/apps/details?id=com.jcxsoftware.teleprompter365'
        }
    ]

    videoDemo = 'assets/videos/record-demo-300.mp4'
    
    bannerVideo: Image[] = [
        {
            img: 'assets/img/app-banner.png'
        }
    ]

}
class Content {
    title : string;
    paragraph : string;
    appStoreImage : string;
    appStoreURL : string;
    playStoreImage: string;
    playStoreURL: string;

}
class Image {
    img : string;
}