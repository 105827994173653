import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tpabout',
  templateUrl: './tpabout.component.html',
  styleUrls: ['./tpabout.component.scss']
})
export class TPAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  aboutImage: Image[] = [
    {
        img: 'assets/img/tp1-400w.png'
    }
  ]
  aboutContent = [
    {
        title: 'Use with an external teleprompter',
        paragraphList: [
            {
                paragraph: 'You can use this app with an external teleprompter by enabling the projection mode in the script settings.',
            },
            {
                paragraph: 'In projection mode, you can mirror the screen in your device to allow the proper viewing in the teleprompter mirror.',
            }
        ],
        featuresList: [
            {
                itemIcon: "las la-check",
                item: "Use projection mode to mirror the screen"
            },
            {
                itemIcon: "las la-check",
                item: "In projection mode you use an external camera to record the video"
            },
            {
                itemIcon: "las la-check",
                item: "Start and stop the video using a remote control"
            },
            {
                itemIcon: "las la-check",
                item: "Share your videos in your favorite social media platform"
            },
            {
                itemIcon: "las la-check",
                item: "A tablet is ideal to use with an external teleprompter but a large phone would work too."
            },
            {
                itemIcon: "las la-check",
                item: "Locks the screen in landscape mode to ensure best experience."
            }
        ]
    }
  ];

}

class Image {
  img : string;
}
