import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-feedback',
    templateUrl: './app-feedback.component.html',
    styleUrls: ['./app-feedback.component.scss']
})
export class AppFeedbackComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'User Feedback',
            paragraphText: 'See what users are saying about TelePrompter 365.'
        }
    ]
    singleFeedbackItem: Content[] = [
        {
            feedbackText: 'Using a teleprompter has allowed me to get better at recording videos for school work.',
            userImg: 'assets/img/team/team1.jpg',
            userName: 'Sarah',
            userDesignation: 'Student'
        },
        {
            feedbackText: 'I use this tool to record my trianing video instructions to share with my friends.',
            userImg: 'assets/img/team/team2.jpg',
            userName: 'Sergio',
            userDesignation: 'Cross-fit enthusiast'
        },
        {
            feedbackText: 'Teleprompter 365 is one of the tools I keep in my toolbox. I use this for producing video tutorials',
            userImg: 'assets/img/team/team3.jpg',
            userName: 'Sebastian',
            userDesignation: 'Developer'
        },
        {
            feedbackText: 'Engaging with my customers in social media is a key part of my job. Using a teleprompter has allowed me to get more effective in delivering the messages I want to get to my customers',
            userImg: 'assets/img/team/team4.jpg',
            userName: 'Wanda',
            userDesignation: 'Yoga Instructor'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Content {
    feedbackText : string;
    userImg : string;
    userName : string;
    userDesignation : string;
}