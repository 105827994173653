<script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
<style type="text/css" media="screen, projection">
	@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
</style> 
<div id="home" class="app-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
				<h2>Tech Support</h2>
					<iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://jcxsoftware.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded" scrolling="no" height="500px" width="100%" frameborder="0" >
					</iframe>                
            </div>
            <div class="col-lg-4 col-md-6">
            </div>
        </div>
    </div>
</div>

