import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-features',
    templateUrl: './app-features.component.html',
    styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'App Features',
            paragraphText: 'Teleprompter 365 is a free full-featured teleprompter app for your phone and tablet'
        }
    ]
    singleAppFeatures: FeaturesContent[] = [
        {
            icon: 'las la-cloud',
            title: 'Cloud Connected',
            paragraph: 'Your scripts are saved in the cloud and accessible from any device'
        },
        {
            icon: 'las la-tachometer-alt',
            title: 'Pace your speech',
            paragraph: 'Configure how many words per minute you want your speech be'
        },
        {
            icon: 'las la-share',
            title: 'Share with anyone',
            paragraph: 'You can share your recordings easily or store them in the cloud.'
        },
        {
            icon: 'las la-user',
            title: 'Google and Apple sign in',
            paragraph: 'Log easily into the app using your existing social credentials'
        },
        {
            icon: 'las la-font',
            title: 'Adjust text size',
            paragraph: 'You can adjust the font size to better fit the distance you are from the camera.'
        },
        {
            icon: 'las la-video',
            title: 'Unlimited recordings',
            paragraph: 'Record as many videos as you like. Mark the ones you like favorite'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class FeaturesContent {
    icon : string;
    title : string;
    paragraph : string;
}